import { AxiosStatic } from 'axios'
import { useLang } from '../i18n/useLang'
import { useNavigate } from 'react-router-dom'
import { ArgsProps } from 'antd/es/notification/interface'

export function setupAxios(
  axios: AxiosStatic,
  displayNotification: (notification: ArgsProps) => void
) {
  const navigate = useNavigate()
  const { selectedLocale } = useLang()
  // Requests default headers
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['Accept-Language'] = selectedLocale

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      config.headers.Accept = 'application/json'

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        /**
         * The API returned a 401 - Unauthorized status code
         */

        // First of all, mark the request as retried
        originalRequest._retry = true

        // No refresh token system on File sharing
        // Therefore redirect to login
        navigate('/')
      }

      return Promise.reject(error)
    }
  )
}
