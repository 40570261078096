enum LocalizationKey {
  /**
   * File Sharing
   */
  FileSharingExpires = 'FileSharing-Expires',
  FileSharingDownload = 'FileSharing-Download',
  FileSharingError = 'FileSharing-Error',
}

const LocalizationKeys = {
  FileSharing: {
    Expires: LocalizationKey.FileSharingExpires,
    Download: LocalizationKey.FileSharingDownload,
    Error: LocalizationKey.FileSharingError,
  },
}

export { LocalizationKey }
export default LocalizationKeys
