import axios from 'axios'
import { FILE_SHARING_URL } from '../utils/urls'

export async function getPublicLinkDataForUuid(uuid: string) {
  return await axios
    .get(`${FILE_SHARING_URL}/${uuid}/data`)
    .then((response) => response.data.data)
}

export async function getPublicLinkDownloadLinkForUuid(uuid: string) {
  return await axios
    .get(`${FILE_SHARING_URL}/${uuid}/download-link`)
    .then((response) => response.data.data)
}
