import { Card, Space, Typography } from 'antd'

const { Title } = Typography

const Home = () => {
  return (
    <Card bordered={false} style={{ width: '300px' }}>
      <Space
        direction="vertical"
        style={{ textAlign: 'center', width: '100%' }}
        size={20}
      >
        <div className="logo">
          <img
            src={'/assets/images/logocurve.png'}
            height={100}
            style={{ marginTop: 12 }}
          />
        </div>
        <Title>File sharing</Title>
      </Space>
    </Card>
  )
}

export default Home
