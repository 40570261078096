import './App.css'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import axios from 'axios'
import frFR from 'antd/locale/fr_FR'
import { useLang } from './i18n/useLang'
import { setupAxios } from './utils/setupAxios'
import { ArgsProps } from 'antd/lib/notification/interface'
import { App as ANTDApp, ConfigProvider, theme } from 'antd'
import { LayoutSemiColumn } from '@supplement-bacon/alela-uikit'

const { defaultAlgorithm, darkAlgorithm } = theme

function App() {
  const { notification } = ANTDApp.useApp()
  const { selectedLocale } = useLang()

  /**
   * Setup dayjs locale globally
   */
  dayjs.locale(selectedLocale)

  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios, (args: ArgsProps) => {
    notification.error({ ...args, placement: 'bottomRight' })
  })

  return (
    <ConfigProvider
      locale={selectedLocale === 'fr' ? frFR : undefined}
      theme={{
        algorithm: darkAlgorithm,
        token: {
          colorPrimary: '#5d5df6',
          colorBgBase: '#000000',
          colorBgLayout: '#1b1a1a',
          colorBgContainer: '#1E1E1E',
        },
      }}
    >
      <ANTDApp>
        <LayoutSemiColumn background="url('/assets/images/file-sharing-background.jpg')" />
      </ANTDApp>
    </ConfigProvider>
  )
}

export default App
