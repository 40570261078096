import { LocalizationKey } from '../LocalizationKeys'

const EN: Record<LocalizationKey, string> = {
  /**
   * FILE SHARING
   */

  [LocalizationKey.FileSharingExpires]: 'Expires',
  [LocalizationKey.FileSharingDownload]: 'Download',
  [LocalizationKey.FileSharingError]: 'This link does not exist or is expired',
}

export default EN
