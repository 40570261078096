import './index.css'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { inject } from '@vercel/analytics'
import { AppRoutes } from './routes/AppRoutes'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from './i18n/i18nProvider'

/**
 * Sentry
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'alela-file-sharing@' + process.env.REACT_APP_VERSION,
})

// Enable Vercel analytics
inject({
  mode:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'production'
      : 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter basename="/">
    <I18nProvider>
      <AppRoutes />
    </I18nProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
