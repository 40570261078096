import { Space, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'

const { Text } = Typography

const Error = () => {
  const intl = useIntl()

  return (
    <Space
      direction="vertical"
      style={{ textAlign: 'center', width: '100%' }}
      size={20}
    >
      <CloseCircleOutlined style={{ color: 'red', fontSize: '40px' }} />
      <Text strong>
        {intl.formatMessage({ id: LocalizationKeys.FileSharing.Error })}
      </Text>
    </Space>
  )
}

export default Error
