import {
  getPublicLinkDataForUuid,
  getPublicLinkDownloadLinkForUuid,
} from '../services/FileSharingService'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Card, Space, Typography } from 'antd'
import { PublicLink } from '../models/PublicLink'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../i18n/LocalizationKeys'
import Error from '../components/Error'

const { Text } = Typography

const FileSharing = () => {
  dayjs.extend(relativeTime)
  const intl = useIntl()
  const { uuid } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [linkIsLoading, setLinkIsLoading] = useState<boolean>(false)
  const [publicLink, setPublicLink] = useState<PublicLink | undefined>(
    undefined
  )

  const downloadFile = () => {
    setLinkIsLoading(true)
    getPublicLinkDownloadLinkForUuid(uuid!).then((result) => {
      window.location.href = result.downloadUrl
      setLinkIsLoading(false)
    })
  }

  useEffect(() => {
    if (!uuid) {
      return
    }

    setHasError(false)
    setIsLoading(true)
    getPublicLinkDataForUuid(uuid)
      .then((result) => {
        setPublicLink(result)
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false))
  }, [uuid])

  return (
    <Card bordered={false} loading={isLoading} style={{ width: '300px' }}>
      {hasError === false && (
        <Space
          direction="vertical"
          style={{ textAlign: 'center', width: '100%' }}
          size={20}
        >
          <img src={'/assets/images/file-download.svg'}></img>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Text strong>{publicLink?.file?.name}</Text>
            <Text type="secondary">
              {intl.formatMessage({ id: LocalizationKeys.FileSharing.Expires })}{' '}
              {dayjs(publicLink?.expiresAt).fromNow()}
            </Text>
          </Space>
          <Button
            size="large"
            className="w-full"
            type="primary"
            loading={linkIsLoading}
            onClick={downloadFile}
          >
            {intl.formatMessage({ id: LocalizationKeys.FileSharing.Download })}
          </Button>
        </Space>
      )}
      {hasError === true && <Error />}
    </Card>
  )
}

export default FileSharing
