import App from '../App'
import Home from '../pages/Home'
import { FC, useLayoutEffect } from 'react'
import FileSharing from '../pages/FileSharing'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

const AppRoutes: FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<App />}>
        <Route path=":uuid" element={<FileSharing />} />
        <Route path="" element={<Home />} />

        <Route index path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export { AppRoutes }
