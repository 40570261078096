import { LocalizationKey } from '../LocalizationKeys'

const FR: Record<LocalizationKey, string> = {
  /**
   * FILE SHARING
   */

  [LocalizationKey.FileSharingExpires]: 'Expire',
  [LocalizationKey.FileSharingDownload]: 'Télécharger',
  [LocalizationKey.FileSharingError]: "Ce lien n'existe pas ou est expiré",
}

export default FR
